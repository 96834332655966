import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { AuthInfo, AuthInfoDTO } from 'app/modules/auth/models';
import { User, UserDTO } from '../models';
import { UserFiltersI, UsersResponseI, InfoResponseI } from '../interfaces';
import { Profile, ProfileDTO } from '../models/profile';

interface UserCreateDTO {
  authId: string;
  email: string;
  username: string;
  userPlan: string;
  localeLanguage: string;
  firstName?: string;
  lastName?: string;
  telephone?: string;
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
}
interface UsersRespDTOI {
  message: string;
  users: Array<UserDTO>;
}
interface UserRespDTOI {
  message: string;
  user: UserDTO;
}
interface AuthsRespDTOI {
  message: string;
  auths: Array<AuthInfoDTO>;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl: string = environment.apiUrl;

  private users: Array<User>;

  setUsers(newUsers: Array<User>): void {
    this.users = [];
    this.users.push(...newUsers);
  }

  get getUsers(): Array<User> {
    return this.users;
  }

  getUserById(id: string): User {
    return this.users?.find((user) => user.id === id);
  }

  constructor(private http: HttpClient) { }

  async getAllUsers(offset: number, limit: number, filters: UserFiltersI): Promise<UsersResponseI> {
    let usersFetched: Array<User>;
    let infoResponse: InfoResponseI;

    if (environment.useMocks) {
      //
    } else {
      const urlUsers = `${this.apiUrl}/admin/users?disabled=1`;
      const responseUsers: UsersRespDTOI = await this.http.get<UsersRespDTOI>(urlUsers).toPromise();
      const allUsers: Array<User> = new User().deserializeArray(responseUsers.users)
        .filter((auth: User) => {
          if (!filters.text) {
            return true;
          }

          return auth.email.includes(filters.text) || auth.username.includes(filters.text);
        });
      usersFetched = allUsers.slice(offset, offset + limit);
      infoResponse = {
        count: allUsers.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };
      this.setUsers(usersFetched);
    }

    return {
      users: usersFetched,
      info: infoResponse,
    };
  }

  private async getUserProfile(userId: string): Promise<User> {
    let profileFetched: User;

    if (environment.useMocks) {
      // const user: ProfileDTO = USERS_MOCK.find((u: ProfileDTO) => u.auth.id === userId);
      // profileFetched = new Profile().deserialize(user);
    } else {
      const url = `${this.apiUrl}/admin/users/${userId}/`;
      const response: UserRespDTOI = await this.http.get<UserRespDTOI>(url).toPromise();
      const profileDTO = response.user;
      profileFetched = new User().deserialize(profileDTO);
    }
    return profileFetched;
  }

  async getUser(id: string): Promise<User> {
    // const auth = await this.getUserAuth(id);
    let user = new User();
    try {
      user = await this.getUserProfile(id);
    } catch (error) {

    }

    return user;
  }

  private async createUserAuth(user: User, password: string): Promise<AuthInfo> {
    let authInfo : AuthInfo = null;
    const url = `${this.apiUrl}/admin/auth/`;
    const userAuthBody = {
      email: user.email,
      username: user.username,
      password,
      userRole: user.role,
    };
    const { auth: newAuthDTO } = await this.http.post<{auth: AuthInfoDTO}>(url, userAuthBody).toPromise();
    authInfo = new AuthInfo().deserialize(newAuthDTO);
    return authInfo;
  }

  private async createUserProfile(user: User, userId: string): Promise<User> {
    const url = `${this.apiUrl}/admin/users/`;
    const userProfileBody: UserCreateDTO = {
      authId: userId,
      email: user.email,
      username: user.username,
      userPlan: user.userPlan,
      localeLanguage: 'en',
      firstName: user.firstName,
      lastName: user.lastName,
      telephone: user.telephone,
      country: user.country,
      state: user.state,
      city: user.city,
      zipCode: user.zipCode,
    };

    const response = await this.http.post<UserRespDTOI>(url, userProfileBody).toPromise();
    const profileInfo = new User().deserialize(response.user);
    return profileInfo;
  }

  async createUser(userToCreate: User, password: string): Promise<User> {
    let newUser: User;
    let newAuth: AuthInfo;
    if (environment.useMocks) {
      newUser = userToCreate;
    } else {
      newAuth = await this.createUserAuth(userToCreate, password);
      if (newAuth) {
        newUser = await this.createUserProfile(userToCreate, newAuth.id);
      }
    }
    return newUser;
  }

  async updateUserProfile(userUpdated: User, userId: string): Promise<Profile> {
    let updatedUser: Profile;
    if (!environment.useMocks) {
      // Update auth
      // const currentUser = await this.getUserAuth(userId);
      // if (currentUser.username !== userUpdated.username || currentUser.email !== userUpdated.email) {
      //   const authUrl = `${this.apiUrl}/admin/auth/${userId}/`;
      //   updatedAuthDTO = await this.http.put<AuthInfoDTO>(authUrl, userUpdated).toPromise();
      // }
      const url = `${this.apiUrl}/admin/users/${userId}/`;
      const updatedProfileDTO: ProfileDTO = await this.http.put<ProfileDTO>(url, userUpdated).toPromise();

      updatedUser = new Profile().deserialize(updatedProfileDTO);
    }
    return updatedUser;
  }

  async changePassword(userId: string, newPassword: string): Promise<any> {
    if (!environment.useMocks) {
      const url = `${this.apiUrl}/admin/auth/${userId}/psw/`;
      await this.http.put<ProfileDTO>(url, { newPassword }).toPromise();
    }
  }

  async softDeleteUser(userId: string): Promise<void> {
    if (!environment.useMocks) {
      const url = `${this.apiUrl}/admin/users/${userId}/disable`;
      await this.http.post<ProfileDTO>(url, {}).toPromise();
    }
  }
  // userAdminRoutes.post('/users/:id/enable', userEnableByAdmin);

  async getUsersWithPaymentAcct(): Promise<Array<User>> {
    let usersFetched: Array<User> = [];
    if (environment.useMocks) {
      // useMocks
    } else {
      const url = `${this.apiUrl}/admin/users?disabled=1`;
      const { users } = await this.http.get<{users: Array<UserDTO>}>(url).toPromise();
      usersFetched = new User().deserializeArray(users);
    }

    return usersFetched;
  }
}
