import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';
import { Session } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { AuthInfo, AuthInfoDTO } from '../models';
import { AUTH_INFO_MOCK } from '../mocks';

interface AuthResponse {
  message: string;
  token: string;
  auth: AuthInfoDTO;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly sessionSubject = new BehaviorSubject<Session>(null);
  readonly session$ = this.sessionSubject.asObservable();

  private readonly sessionKey = environment.sessionKey;
  private readonly apiUrl = environment.apiUrl;

  private localStorageService: Storage;

  constructor(
    private http: HttpClient,
  ) {
    this.localStorageService = localStorage;
    this.session = this.loadSessionData();
  }

  getCurrentSession(): Session {
    return this.sessionSubject.getValue();
  }

  private set session(session: Session) {
    this.sessionSubject.next(session);
    this.localStorageService.setItem(this.sessionKey, JSON.stringify(session));
  }

  loadSessionData(): Session {
    const sessionStr = this.localStorageService.getItem(this.sessionKey);
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }

  getCurrentUser(): AuthInfo {
    const session: Session = this.getCurrentSession();
    return (session && session.authInfo) ? session.authInfo : null;
  }

  getCurrentToken(): string {
    const session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  }

  isAuthenticated(): boolean {
    return (this.getCurrentToken() !== null);
  }

  removeSession(): void {
    this.session = null;
  }

  async login(email: string, password: string): Promise<Session> {
    let session: Session = null;

    if (environment.useMocks) {
      session = {
        authInfo: new AuthInfo().deserialize(AUTH_INFO_MOCK),
        token: 'asdasdasdasd',
      };
    } else {
      const authUrl = `${this.apiUrl}/public/auth/login`;

      const authResponse: AuthResponse = await this.http.post<AuthResponse>(
        authUrl,
        { email, password },
      ).toPromise();

      const { token, auth } = authResponse;

      session = {
        authInfo: new AuthInfo().deserialize(auth),
        token,
      };
    }

    this.session = session;
    return session;
  }

  logout(): void {
    this.removeSession();
  }
}
