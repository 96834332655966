import { Serializable } from 'app/core/interfaces';
import { AuthInfo, UserType } from 'app/modules/auth/models';
import { ClientRoleType, Profile } from './profile';
import { Subscription, SubscriptionDTO } from './subscription';

export interface PaymentAccountDTO {
  authId: string;
  lastSubscription: SubscriptionDTO;
  stripeCustomerId: string;
  stripeDefaultPaymentMethodId: string;
}

export interface UserTypesSelectI {
  name: string;
  type: ClientRoleType | UserType;
}

export interface UserDTO {
  auth?: AuthInfo;
  profile?: Profile;
  authId: string;
  email: string;
  username: string;
  userPlan: 'business' | 'personal';
  localeLanguage: string;
  disabled: boolean;
  firstName: string;
  lastName: string;
  avatar: string;
  telephone: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  createdAt?: string;
  updatedAt?: string;

  payAcct: PaymentAccountDTO;
}

export class User implements Serializable<User> {
  id: string;
  username: string;
  email: string;
  isEmailValidated: boolean;

  userPlan: ClientRoleType;
  role: UserType;

  avatar: string;
  firstName: string;
  lastName: string;
  telephone: string;

  country: string;
  state: string;
  city: string;
  zipCode: string;

  subscription?: Subscription;

  deserialize(input: UserDTO): User {
    if (!input) {
      return this;
    }

    this.id = input.authId || '';
    this.username = input.username || '';
    this.email = input.email || '';
    // this.isEmailValidated = input.isEmailValidated || false;
    this.role = UserType.CLIENT;

    this.userPlan = input.userPlan as ClientRoleType;
    this.avatar = input.avatar || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.telephone = input.telephone || '';

    this.country = input.country || '';
    this.state = input.state || '';
    this.city = input.city || '';
    this.zipCode = input.zipCode || '';

    this.subscription = new Subscription().deserialize(input.payAcct?.lastSubscription);
    return this;
  }

  deserializeArray(inputArray: Array<UserDTO>): Array<User> {
    const usersArray: Array<User> = inputArray.map(
      (input) => new User().deserialize(input),
    );

    return usersArray;
  }
}
