import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/modules/auth/services';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { AuthInfo } from 'app/modules/auth/models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  public authInfo: AuthInfo;
  private subscription: Subscription;
  public faQuestionCircle = faQuestionCircle;

  constructor(
    private authService: AuthService,
  ) {
    this.subscription = this.authService.session$.subscribe((session) => {
      this.authInfo = session.authInfo;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
