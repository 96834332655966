import { NavItemI } from 'app/core/interfaces';

import { environment } from 'environments/environment';

export const navigation: Array<NavItemI> = [
  // Admin nav items
  {
    name: 'User Management',
    url: '/users',
  },
  {
    name: 'Statistics',
    url: '/statistics',
  },
  {
    name: 'Test',
    url: '/test',
    hidden: !environment.development,
  },
];
