import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSidebarToggler]',
})
export class SidebarToggleDirective {
  constructor() { }

  @HostListener('click', ['$event'])
  toggleOpen($event: Event): void {
    $event.preventDefault();

    document.querySelector('.layout').classList.toggle('show-sidebar');

    const sidebarTogglerOpen = document.querySelector('.sidebar-toggler-open');
    if (sidebarTogglerOpen) {
      sidebarTogglerOpen.classList.toggle('show');
    }

    const sidebarTogglerClose = document.querySelector('.sidebar-toggler-close');
    if (sidebarTogglerClose) {
      sidebarTogglerClose.classList.toggle('show');
    }
  }
}
