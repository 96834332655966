import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNavDropdown]',
})
export class NavDropdownDirective {
  constructor(private el: ElementRef<HTMLElement>) { }

  toggle(): void {
    if (!this.el.nativeElement) {
      return;
    }

    this.el.nativeElement.classList.toggle('show');
    this.el.nativeElement.querySelector('.dropdown-menu').classList.toggle('show');
  }

  remove(): void {
    if (!this.el.nativeElement) {
      return;
    }

    this.el.nativeElement.classList.remove('show');
    this.el.nativeElement.querySelector('.dropdown-menu').classList.remove('show');
  }
}

/**
 * Allows the dropdown to be toggled via click.
 */
@Directive({
  selector: '[appNavDropdownToggle]',
})
export class NavDropdownToggleDirective {
  constructor(private dropdown: NavDropdownDirective, private el: ElementRef<HTMLElement>) {}

  @HostListener('click', ['$event'])
  toggleOpen($event: MouseEvent): void {
    const clickedInside = this.el.nativeElement.contains(<HTMLInputElement> $event.target);

    if (clickedInside) {
      $event.preventDefault();
      this.dropdown.toggle();
    } else {
      this.dropdown.remove();
    }
  }
}

export const NAV_DROPDOWN_DIRECTIVES = [NavDropdownDirective, NavDropdownToggleDirective];
