import { Serializable } from 'app/core/interfaces';

export enum ClientRoleType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}
export interface ProfileDTO {
  authId: string;
  username: string;

  userPlan: ClientRoleType;
  avatar: string;
  firstName: string;
  lastName: string;
  telephone: string;

  country: string;
  state: string;
  city: string;
  zipCode: string;
}

export class Profile implements Serializable<Profile> {
  id: string;
  username: string;
  userPlan: ClientRoleType;
  avatar: string;
  firstName: string;
  lastName: string;
  telephone: string;

  country: string;
  state: string;
  city: string;
  zipCode: string;

  deserialize(input: ProfileDTO): Profile {
    if (!input) {
      return this;
    }
    this.id = input.authId || '';
    this.username = input.username || '';

    this.userPlan = input.userPlan;
    this.avatar = input.avatar || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.telephone = input.telephone || '';

    this.country = input.country || '';
    this.state = input.state || '';
    this.city = input.city || '';
    this.zipCode = input.zipCode || '';

    return this;
  }

  deserializeArray(inputArray: Array<ProfileDTO>): Array<Profile> {
    const profilesArray: Array<Profile> = inputArray.map(
      (input) => new Profile().deserialize(input),
    );

    return profilesArray;
  }
}
