import {
  ModuleWithProviders, NgModule, Provider,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';

import { LoggedInGuard } from 'app/modules/auth/guards';
import { AuthService } from 'app/modules/auth/services';

import { TruncatePipe } from 'app/shared/pipes/truncate.pipe';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserService, SubscriptionService, ExportFileService } from './services';

import {
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  APP_SIDEBAR_NAV,
  ModalProgressComponent,
} from './components';
import { SidebarToggleDirective, NAV_DROPDOWN_DIRECTIVES } from './directives';
import { P404PageComponent } from './pages';
import { TokenInterceptorService } from './services/token-interceptor.service';

const PAGES: any[] = [
  P404PageComponent,
];

const COMPONENTS: any[] = [
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  ...APP_SIDEBAR_NAV,
  ModalProgressComponent,
];

const PROVIDERS: Provider[] = [
  AuthService,
  LoggedInGuard,
  UserService,
  SubscriptionService,
  ExportFileService,
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
];

const MODULES: any[] = [
  RouterModule,
  CommonModule,
  FormsModule,
  NgSelectModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  NgxSpinnerModule,
  ChartsModule,
  NgxPaginationModule,
  NgbModule,
];

const DIRECTIVES: Provider[] = [
  SidebarToggleDirective,
  ...NAV_DROPDOWN_DIRECTIVES,
];

const PIPES: any[] = [
  TruncatePipe,
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    ...MODULES as [],
    HttpClientModule,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    ...PAGES as [],
    ...COMPONENTS as [],
    ...DIRECTIVES as [],
    ...PIPES as [],
  ],
  exports: [
    ...PAGES as [],
    ...COMPONENTS as [],
    ...DIRECTIVES as [],
    ...MODULES as [],
    ...PIPES as [],
    TranslateModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS, ...DIRECTIVES],
    } as ModuleWithProviders<SharedModule>;
  }

  constructor(private translate: TranslateService) {
    this.initTranslateService();
  }

  private initTranslateService(): void {
    // this.translate.addLangs(['es', 'en']);
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');

    // user browser language
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }
}
