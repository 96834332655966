<div class="d-flex flex-column min-vh-100 sidebar">
  <a
    routerLink="/"
    routerLinkActive="active"
    class="p-2 mb-2 d-block text-center image-container"
  >
    <img class="logo" src="/assets/images/moneygrit-admin-logo.png" alt="MoneyGrit Logo" />
  </a>
  <div class="app-user py-3 px-2">
    <a
      routerLink="/profile"
      routerLinkActive="active"
      class="d-block text-left px-2 font-size-lg font-primary-medium"
    >
      <img src="//placehold.it/300" class="rounded-circle user-avatar" />
      <span class="ml-2">{{ authInfo.username }}</span>
    </a>
  </div>
  <app-sidebar-menu></app-sidebar-menu>
</div>
