import { Serializable } from 'app/core/interfaces';
import moment from 'moment';

export enum PeriodType {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
  CUSTOM = 'custom',
}

export enum PlanType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

/*
 * StateType:
 *   - active: active.
 *   - canceled: canceled.
 *   - incomplete: if the initial payment attempt fails.
 *   - incomplete_expired: if the first invoice is not paid within 23 hours.
 *   - past_due: when its invoice is not paid by the due date.
 *   - trialing: a subscription that is currently in a trial period.
 *   - unpaid: when still not paid by an additional deadline after due date.
 */
export enum StateType {
  INCOMPLETE = 'incomplete', // INCOMPLETE
  ACTIVE = 'active', // ACTIVE
  EXPIRED = 'expired', // INACTIVE
  CANCELED = 'canceled', // INACTIVE
  INCOMPLETE_EXPIRED = 'incomplete_expired', // INACTIVE
  PAST_DUE = 'past_due', // INCOMPLETE
  TRIALING = 'trialing', // ACTIVE
  UNPAID = 'unpaid', // INACTIVE
}
export class UserSubscription {
  email: string;
  firstName: string;
  lastName: string;
  periodEnd: Date;
  periodStart: Date;
}
export interface SubscriptionDTO {
  subId: string;
  owner: string;
  plan: PlanType;
  interval: PeriodType;
  status: StateType;
  periodStart: string;
  periodEnd: string;
  cancelAtPeriodEnd: boolean;
}
export class Subscription implements Serializable<Subscription> {
  subId: string;
  owner: string;
  plan: PlanType;
  period: PeriodType;
  status: StateType;
  periodStart: Date;
  periodEnd: Date;
  cancelAtPeriodEnd: boolean;

  deserialize(input: SubscriptionDTO): Subscription {
    if (!input) {
      return this;
    }

    this.subId = input.subId || '';
    this.owner = input.owner || '';
    this.plan = input.plan || PlanType.PERSONAL;
    this.period = input.interval || PeriodType.CUSTOM;
    this.status = input.status || StateType.ACTIVE;
    this.periodStart = input.periodStart ? new Date(input.periodStart) : null;
    this.periodEnd = input.periodEnd ? new Date(input.periodEnd) : null;
    this.cancelAtPeriodEnd = input.cancelAtPeriodEnd || false;

    return this;
  }

  deserializeArray(inputArray: Array<SubscriptionDTO>): Array<Subscription> {
    const SubscriptionsArray: Array<Subscription> = inputArray.map(
      (input) => new Subscription().deserialize(input),
    );

    return SubscriptionsArray;
  }

  get hasSubscription(): boolean {
    return !!this.subId && this.isActive;
  }

  get subscriptionStarted(): boolean {
    const isSameOrAfter = moment().isSameOrAfter(this.periodStart);

    return this.hasSubscription && isSameOrAfter;
  }

  get subscriptionExpired(): boolean {
    const isAfter = moment().isAfter(this.periodEnd);
    return this.hasSubscription && isAfter;
  }

  // #### TYPE #################################################################

  get isCustom(): boolean {
    return this.period === PeriodType.CUSTOM;
  }

  get isPayment(): boolean {
    return this.period === PeriodType.MONTHLY || this.period === PeriodType.ANNUAL;
  }

  // #### STATUS #################################################################

  get isActive(): boolean {
    return this.status === StateType.ACTIVE || this.status === StateType.TRIALING;
  }

  get isValid(): boolean {
    let result = false;

    if (this.isCustom) {
      result = this.isActive && this.subscriptionStarted && !this.subscriptionExpired;
    } else if (this.isPayment) {
      result = this.isActive;
    }

    return result;
  }

  get isInactive(): boolean {
    if (this.isPayment) {
      return (
        this.status === StateType.CANCELED
        || this.status === StateType.EXPIRED
        || this.status === StateType.INCOMPLETE_EXPIRED
        || this.status === StateType.UNPAID
        || this.status === StateType.PAST_DUE
      );
    }

    if (this.isCustom) {
      return !this.isValid;
    }

    return true;
  }

  get isIncomplete(): boolean {
    return this.status === StateType.INCOMPLETE;
  }

  get isPastDue(): boolean {
    return this.status === StateType.PAST_DUE;
  }
  // ###########################################################################
}
