import { Component, OnInit } from '@angular/core';

import { faQuestion } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  faQuestion = faQuestion;

  constructor() { }

  ngOnInit(): void {
  }
}
