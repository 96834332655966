import { AuthInfoDTO, UserType } from '../models';

export const AUTH_INFO_MOCK: AuthInfoDTO = {
  id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
  email: 'rtarre@nakima.es',
  isEmailValidated: true,
  username: 'rtarre',
  role: UserType.CLIENT,
};

export const AUTHS_INFO_MOCK: Array<AuthInfoDTO> = [
  {
    id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
    email: 'rtarre@nakima.es',
    isEmailValidated: true,
    username: 'rtarre',
    role: UserType.CLIENT,
  },
  {
    id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
    email: 'jgimenez@nakima.es',
    isEmailValidated: true,
    username: 'jgimenez',
    role: UserType.CLIENT,
  },
  {
    id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
    email: 'mbosch@nakima.es',
    isEmailValidated: true,
    username: 'mbosch',
    role: UserType.CLIENT,
  },
  {
    id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
    email: 'naguilera@nakima.es',
    isEmailValidated: true,
    username: 'naguilera',
    role: UserType.CLIENT,
  },
];
