import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class ExportFileService {
  constructor() {}

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  convertToCSV(content: string, fileName: string): void {
    this.saveAsFile(content, `${fileName}.csv`, 'text/plain;charset=utf-8');
  }
}
