import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

export interface DataI {
  text: string;
  url: string;
}
@Component({
  selector: 'app-p404-page',
  templateUrl: './p404-page.component.html',
  styleUrls: ['./p404-page.component.scss'],
})
export class P404PageComponent implements OnInit, OnDestroy {
  info: DataI = {
    text: null,
    url: null,
  };

  subscription: Subscription;
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscription = this.route.data.subscribe((data: DataI) => { this.info = data; });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
