import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  Subscription, SubscriptionDTO, UserSubscription,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async getUserSubscription(clientId: string): Promise<Subscription> {
    let response: SubscriptionDTO;
    if (environment.useMocks) {
    // mock
    } else {
      const url = `${this.apiUrl}/admin/payments/subscriptions/users/${clientId}/current/`;

      const { subscription } = await this.http.get<{subscription: SubscriptionDTO}>(
        url,
      ).toPromise();
      response = subscription;
    }
    return new Subscription().deserialize(response);
  }

  async createUserSubscription(userId: string, iniDate: string, finDate: string): Promise<Subscription> {
    let response: SubscriptionDTO;
    if (environment.useMocks) {
    // mock
    } else {
      const url = `${this.apiUrl}/admin/payments/subscriptions/custom/`;

      const { subscription } = await this.http.post<{subscription: SubscriptionDTO}>(
        url,
        {
          authId: userId,
          startDate: iniDate,
          finDate,
        },
      ).toPromise();

      response = subscription;
    }
    return new Subscription().deserialize(response);
  }

  async cancelUserSubscription(authId: string): Promise<HttpResponse<any>> {
    let response: HttpResponse<any>;
    if (environment.useMocks) {
      // mock
    } else {
      const url = `${this.apiUrl}/admin/payments/subscriptions/users/${authId}/cancel/`;

      response = await this.http.post<HttpResponse<any>>(url, {}).toPromise();
    }
    return response;
  }

  async getUsersWithSubscription(): Promise<Array<UserSubscription>> {
    let usersFetched: Array<UserSubscription> = [];
    if (environment.useMocks) {
      // useMocks
    } else {
      const url = `${this.apiUrl}/admin/payments/subscriptions/users/`;
      const { users } = await this.http.get<{users: Array<UserSubscription>}>(url).toPromise();
      usersFetched = users;
    }

    return usersFetched;
  }
}
